<script>
import { Bar } from "vue-chartjs";
import { eventBus } from "../../main";
// @vuese
// @group Components
export default {
  extends: Bar,
  props: ["labels", "datasets", "timeframe", "title", "not_stacked", "type", "legend", "cam_objects"],
  data() {
    return {
      selected_area: {},
      selected_chart_arr: {},
      options_line: {
        onClick: this.redirect_to_images,
        title: {
          display: false,
          text: this.title,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: {
          display: this.datasets.length > 1 && this.legend,
        },
        scales: {
          xAxes: [
            {
              stacked: !this.not_stacked ? true : false,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: !this.not_stacked ? true : false,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    redirect_to_images(event, arr) {
      if (this._data._chart.getElementAtEvent(event)) {
        let dataset_index = this._data._chart.getElementAtEvent(event)[0]
          ? this._data._chart.getElementAtEvent(event)[0]._datasetIndex
          : false;
        if (dataset_index === false || !arr[dataset_index]) {
          return 0;
        }
        if (
          this.selected_area.label != arr[dataset_index]._model.label ||
          this.selected_area.index != dataset_index
        ) {
          this.selected_area = {
            label: arr[dataset_index]._model.label,
            index: dataset_index,
          };
          if (this.selected_area.index !== undefined) {
            this.selected_chart_arr.width -= 4;
          }
          arr[dataset_index]._model.width += 4;
          this.selected_chart_arr = arr[dataset_index]._model;
          
          eventBus.$emit("clear_selected_area", this.type);
          var filter_object = {}
          if (this.type == "by_camera") {
            filter_object = {camera_id: [this.cam_objects[this.datasets[this.selected_area.index].label]]}
          } else if (this.type == "by_class") {
            filter_object = {class: this.datasets[this.selected_area.index].label.trim(), camera_id: []}
          }
          filter_object.time_displace = arr[0]._index
          filter_object.start_time = this.labels[0]
          eventBus.$emit("update_analytics_images_filter", filter_object);
        } else {
          this.selected_chart_arr.width -= 4;
          this.selected_area = {};
          this.selected_chart_arr = {};
          eventBus.$emit("update_analytics_images_filter", {
            reset: true,
            camera_id: [],
            class: {}
          });
        }
      }
    }
  },
  computed: {
    data() {
      return {
        data_bar: {
          datasets: this.datasets,

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },
  },
  created() {
    eventBus.$on("clear_selected_area", (chart) => {
      if (chart != this.type) {
        if (this.selected_area.label) {
          this.renderChart(this.data.data_bar, this.options_line);

          this.selected_area = {};
          this.selected_chart_arr = {};
        }
      }
    });
  },
  mounted() {
    if (
      (this.datasets[0].data && this.datasets[0].data.length > 1) ||
      (this.datasets[1] &&
        this.datasets[1].data &&
        this.datasets[1].data.length > 1)
    ) {
      //this.options_line.title.text = this.title + ` (${this.timeframe})`
      this.renderChart(this.data.data_bar, this.options_line);
    }
  },
  watch: {
    legend() {
      this.options_line.legend = {
          display: this.datasets.length > 1 && this.legend,
        }
      this.renderChart(this.data.data_bar, this.options_line);
    },
    datasets: {
      handler: function () {
        // if (this.title == "Inferred Media") {
        //     console.log(this.datasets[0])
        //   }
        if (
          (this.datasets[0].data && this.datasets[0].data.length > 1) ||
          (this.datasets[1] &&
            this.datasets[1].data &&
            this.datasets[1].data.length > 1)
        ) {
          //this.options_line.title.text = this.title + ` (${this.timeframe})`
          this.renderChart(this.data.data_bar, this.options_line);
        }
      },
      deep: true,
    },
  },
};
</script>