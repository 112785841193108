<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer" :sub_path="sub_path"></sidenav>

    <v-container fluid class="pb-0">
      <v-card>
        <v-card-title class="pb-0"
          ><div class="oc_filter_main_div">
            <div>
              <div
                style="width: 180px; display: inline-block; text-align: start"
              >
                <v-autocomplete
                  :items="camera_name_object"
                  :menu-props="{ offsetOverflow: true }"
                  v-model="selected_cameras"
                  filled
                  label="Camera"
                  chips
                  class="assign_select mr-2 standin_label_select assign_select_v font_400"
                  height="32"
                  outlined
                  dense
                  rounded
                  small-chips
                  hide-details=""
                  deletable-chips
                  multiple
                  :full-width="true"
                  :allow-overflow="false"
                  ><template v-slot:selection="{ item, index }">
                    <v-chip
                      close
                      small
                      v-if="index === 0 && item"
                      @click:close="selected_cameras = []"
                    >
                      <span>{{ selected_cameras.length }} Cameras</span>
                    </v-chip>
                  </template></v-autocomplete
                >
              </div>
              <div style="display: inline-block; text-align: start">
                <v-menu
                  :close-on-content-click="false"
                  open-on-hover
                  offset-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div style="width: 200px; display: inline-block">
                      <v-text-field
                        label="Starting From"
                        v-if="date_range"
                        color="grey"
                        style="margin-bottom: 1px"
                        class="assign_select mr-2 pt-2 font_400 button_text_field"
                        height="32"
                        rounded
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        v-model="starting_from_display"
                      >
                      </v-text-field>
                    </div>
                  </template>

                  <v-card height="410">
                    <v-row
                      no-gutters
                      class="pt-3"
                      v-if="!date_range.includes('__')"
                    >
                      <v-col cols="6">
                        <v-date-picker
                          class="ml-3"
                          v-model="date_range"
                          flat
                          id="video_dp"
                          event-color="#ff9900"
                          :max="todays_date"
                          :min="min_date"
                        ></v-date-picker>
                      </v-col>
                      <v-col cols="6">
                        <v-time-picker
                          class="mr-6 ml-3"
                          v-model="time_range"
                          flat
                        ></v-time-picker>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-menu>
                <div
                  style="width: 120px; display: inline-block; text-align: start"
                >
                  <v-select
                    class="assign_select mr-2 pt-2 font_400 pagination_select"
                    style="bottom: -1px"
                    height="32"
                    label="Group by"
                    v-model="buckets"
                    :items="['5 min', '1 hr', '1 day']"
                    outlined
                    dense
                    rounded
                    hide-details
                  ></v-select>
                </div>
                <div style="width: 100px; display: inline-block" class="">
                  <v-select
                    class="assign_select pt-2 mr-2 font_400 pagination_select"
                    tyle="bottom: -1px"
                    height="32"
                    label="How many"
                    v-model="bucket_size"
                    :items="how_many_items"
                    outlined
                    dense
                    rounded
                    hide-details
                  ></v-select>
                </div>
              </div>
            </div>
            <div style="float: right" class="mt-1">
              <div style="width: 86px; float: left" class="mr-2">
                <v-select
                  class="assign_select assign_select_v font_400 pagination_select mt-1"
                  style="bottom: -1px"
                  height="32"
                  v-model="per_row"
                  :items="row_limits"
                  outlined
                  dense
                  rounded
                  hide-details
                ></v-select>
              </div>
              <v-tooltip color="black" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="refresh"
                    class="mt-1"
                    color="primary"
                    >mdi-refresh</v-icon
                  >
                </template>
                <div>Refresh</div>
              </v-tooltip>
              <v-tooltip color="black" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon
                      @click="legend = !legend"
                      class="mt-1"
                      v-if="!legend"
                      >mdi-chart-timeline</v-icon
                    >
                    <v-icon
                      @click="legend = !legend"
                      class="mt-1"
                      v-else
                      color="primary"
                      >mdi-chart-timeline</v-icon
                    ></span
                  >
                </template>
                <div v-if="!legend">Show legend</div>
                <div v-else>Hide legend</div>
              </v-tooltip>
              <v-tooltip color="black" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon
                      @click="show_bbox = !show_bbox"
                      class="mt-1"
                      v-if="!show_bbox"
                      >mdi-drawing-box</v-icon
                    >
                    <v-icon
                      @click="show_bbox = !show_bbox"
                      class="mt-1"
                      v-else
                      color="primary"
                      >mdi-drawing-box</v-icon
                    ></span
                  >
                </template>
                <div v-if="!show_bbox">Show bounding boxes</div>
                <div v-else>Hide bounding boxes</div>
              </v-tooltip>

              <v-tooltip color="black" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon
                      @click="show_text = !show_text"
                      class="mt-1"
                      v-if="!show_text"
                      >mdi-format-text</v-icon
                    >
                    <v-icon
                      @click="show_text = !show_text"
                      class="mt-1"
                      v-else
                      color="primary"
                      >mdi-format-text</v-icon
                    ></span
                  >
                </template>
                <div v-if="!show_text">Show image info</div>
                <div v-else>Hide image info</div>
              </v-tooltip>

              <v-tooltip color="black" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon
                      @click="show_hover = !show_hover"
                      class="mt-1"
                      :color="show_hover ? 'primary' : null"
                      dense
                    >
                      mdi-cursor-default
                    </v-icon></span
                  >
                </template>
                <div v-if="!show_hover">Enable zoom on hover</div>
                <div v-else>Disable zoom on hover</div>
              </v-tooltip>
            </div>
          </div>
          <div style="width: 100%; display: flex">
            <div class="oc_table_header" style="width: 730px !important">
              <v-tabs v-model="analytics_tabs" class="table_tabs mt-3">
                <v-tab>Detections</v-tab>
                <v-tab>Animals</v-tab>
                <v-tab>Bodyparts</v-tab>
                <v-tab>Calving</v-tab>
                <v-tab>Estrus</v-tab>
                <v-tab>Predator</v-tab>
                <!-- <v-tab to="/videolist">Videos</v-tab> -->
                <!-- <v-tab to="/betsy">Betsy</v-tab> -->
              </v-tabs>
            </div>
            <v-spacer> </v-spacer>
            <span style="font-size: 14px; float: right" class="pt-3">{{
              displaying_images
            }}</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row align-content="center" class="px-0">
            <v-col xl="6" lg="6" md="6" cols="12" class="pb-0">
              <v-card class="charts_card"
                ><chart-summary-media
                  class="analytics_charts mb-2"
                  v-if="!loading_analytics && animal_insights_by_camera"
                  :labels="animal_insights_by_camera[1]"
                  :datasets="animal_insights_by_camera[0]"
                  type="by_camera"
                  :legend="legend"
                  :cam_objects="camera_name_object_2"
                />
                <div v-else-if="loading_analytics" class="analytics_charts">
                  <v-progress-circular
                    size="120"
                    color="primary"
                    indeterminate
                    class="chart_loaders"
                  />
                </div>
                <div
                  v-else-if="!loading_analytics && !animal_insights_by_camera"
                  class="analytics_charts_ph"
                >
                  <span>No Animal Data</span>
                </div>

                <chart-summary-media
                  class="analytics_charts mb-2"
                  v-if="!loading_analytics && animal_insights_by_species"
                  :labels="animal_insights_by_species[1]"
                  :datasets="animal_insights_by_species[0]"
                  type="by_class"
                  :legend="true"
                />
                <div v-else-if="loading_analytics" class="analytics_charts">
                  <v-progress-circular
                    size="120"
                    color="primary"
                    indeterminate
                    class="chart_loaders"
                  />
                </div>
                <div
                  v-else-if="!loading_analytics && !animal_insights_by_species"
                  class="analytics_charts_ph"
                >
                  <span>No Animal Identification Data</span>
                </div>
              </v-card>
            </v-col>
            <v-col class="pb-0" xl="6">
              <div>
                <images
                  v-if="
                    this.$store.getters.getSites &&
                    this.$store.getters.getSites[0]
                  "
                  :filter="image_filter_params"
                  :show_text="show_text"
                  :show_bbox="show_bbox"
                  :show_hover="show_hover"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text> </v-card
    ></v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment-timezone";

import images from "@/components/Images.vue";

import { eventBus } from "../main.js";
import chartSummaryMedia from "../components/summary_charts/chart_summary_media.vue";

export default {
  components: {
    navbar,
    sidenav,
    images,
    chartSummaryMedia,
    //events,
  },
  props: ["tab"],
  data() {
    return {
      per_row: 6,
      row_limits: [2, 4, 6, 8, 12],
      analytics_tabs: 0,
      selected_cameras: [],
      date_range: "2023-__-__",
      todays_date: moment().format("YYYY-MM-DD"),
      min_date: moment().subtract(89, "d").format("YYYY-MM-DD"),

      time_range: "12:00",
      buckets: "1 hr",
      bucket_size: 24,
      group_by_cams: true,

      legend: false,

      loading_analytics: true,

      show_text: true,
      show_bbox: true,
      show_hover: true,

      image_filter_params: {
        start_date: "all",
        group_size: "24_hours",
        camera_id: [],
        model_filter: {},
      },
    };
  },
  methods: {
    refresh() {
      eventBus.$emit("refresh_analytics_images");
    },
    updatePerRow() {
      var container = document.getElementById("grid-container-lf");
      container.style["grid-template-columns"] = `repeat(${this.per_row}, 1fr)`;
    },
    data_exists(data) {
      if (data && data.length) {
        return true;
      }

      return false;
    },
    get_buckets() {
      let unit = "hours";
      if (this.buckets.split(" ")[1] == "min") {
        unit = "minutes";
      } else if (this.buckets.split(" ")[1] == "day") {
        unit = "days";
      }
      return [this.buckets.split(" ")[0], unit];
    },
    get_start_date() {
      if (!this.date_range.includes("__") && this.time_range) {
        return moment(
          this.date_range + " " + this.time_range,
          "YYYY-MM-DD HH:mm"
        );
      }
      return null;
    },
    get_date_range() {
      if (!this.date_range.includes("__") && this.time_range) {
        let buckets = this.buckets.split(" ");
        let sub_unit =
          buckets[1] == "day"
            ? "days"
            : buckets[1] == "hr"
            ? "hours"
            : "minutes";
        let end_date = moment(
          this.date_range + " " + this.time_range,
          "YYYY-MM-DD HH:mm"
        )
          .subtract(buckets[0] * this.bucket_size, sub_unit)
          .format("YYYY/MM/DD hh:mm A");
        return (
          this.weekday(
            moment(end_date, "YYYY/MM/DD hh:mm A").format("YYYY-MM-DD")
          ) +
          " " +
          end_date +
          " - " +
          this.weekday(this.date_range) +
          " " +
          this.date_range.replaceAll("-", "/") +
          " " +
          moment(this.time_range, "HH:mm").format("hh:mm A")
        );
      }
      return "";
    },
    get_analytics() {
      this.loading_analytics = true;

      var params = {
        where: { customer_id: { _eq: this.$store.getters.getCustomerID } },
        tz: this.timezeone_offset,
      };
      let start_time = moment
        .tz(this.date_time_range, "YYYY-MM-DD HH:mm", this.timezeone_offset)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
      let time_unit;
      if (this.buckets.split(" ")[1] == "min") {
        time_unit = "min";
      } else if (this.buckets.split(" ")[1] == "hr") {
        time_unit = "hour";
      } else if (this.buckets.split(" ")[1] == "day") {
        time_unit = "day";
      }
      params.bin_size = `${this.buckets.split(" ")[0]}${time_unit}`;
      params.where.media_timestamp = { _lte: start_time };
      params.range = this.bucket_size * this.buckets.split(" ")[0];

      // params.cameras =
      //   this.selected_cameras && this.selected_cameras.length
      //     ? JSON.stringify(this.selected_cameras)
      //     : null;

      if (this.analytics_tabs == 0) {
        params.query = `summary_pgie_${params.bin_size}_camera`;
        params.group_by_list = [
          "animal_count",
          "person_count",
          "vehicle_count",
        ];
      } else if (this.analytics_tabs == 1) {
        params.query = `summary_species_${params.bin_size}_camera`;
        params.group_by_list = [
          "cow_count",
          "sheep_count",
          "pig_count",
          "goat_count",
          "canine_count",
          "bison_count",
          "cat_count",
          "donkey_count",
          "elk_count",
          "horse_count",
          "llama_count",
        ];
      } else if (this.analytics_tabs == 3) {
        params.query = `summary_analytics_calving_${params.bin_size}_camera`;
        params.group_by_list = ["tail_up_2_count", "tail_down_2_count"];
      } else if (this.analytics_tabs == 2) {
        params.query = `summary_bodyparts_${params.bin_size}_camera`;
        params.group_by_list = [
          "head_count",
          "foot_count",
          "joint_count",
          "tag_count",
          "tail_count",
        ];
      } else if (this.analytics_tabs == 4) {
        params.query = `summary_estrus_${params.bin_size}_camera`;
        params.group_by_list = [
          "in_heat_count",
          "mounting_count",
          "resting_chin_count",
        ];
      } else if (this.analytics_tabs == 5) {
        params.query = `summary_predator_${params.bin_size}_camera`;
        params.group_by_list = ["canine_count", "cat_count"];
      }

      if (this.selected_cameras && this.selected_cameras.length) {
        params.where._or = [];
        for (const i in this.selected_cameras) {
          params.where._or.push({
            camera_id: { _eq: this.selected_cameras[i] },
          });
        }
      }
      this.$store.dispatch("DDB_GET_INSIGHTS", params).then(() => {
        this.loading_analytics = false;
      });
    },
    weekday(date) {
      var dayofweek;
      if (moment(date, "YYYY-MM-DD").weekday() == 0) {
        dayofweek = "Sun";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 1) {
        dayofweek = "Mon";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 2) {
        dayofweek = "Tue";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 3) {
        dayofweek = "Wed";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 4) {
        dayofweek = "Thur";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 5) {
        dayofweek = "Fri";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 6) {
        dayofweek = "Sat";
      }
      return dayofweek;
    },
  },
  computed: {
    sub_path() {
    if (this.analytics_tabs == 1) {
        return "animals";
      } else if (this.analytics_tabs == 2) {
        return "bodyparts";
      }else if (this.analytics_tabs == 3) {
        return "calving";
      } else if (this.analytics_tabs == 4) {
        return "estrus";
      }else if (this.analytics_tabs == 5) {
        return "predator";
      }else {
        return "detections";
      }
    },
    animal_insights_by_camera() {
      return this.$store.getters.getInsightsByCameras.length
        ? this.$store.getters.getInsightsByCameras
        : false;
    },
    animal_insights_by_species() {
      return this.$store.getters.getInsightsBySpecies.length
        ? this.$store.getters.getInsightsBySpecies
        : false;
    },

    camera_name_object_2() {
      var cam_nicknames = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames[this.$store.getters.getCameraObjects[i].name] =
            this.$store.getters.getCameraObjects[i].id;
        }
      }
      return cam_nicknames;
    },
    displaying_images() {
      let model = "All ";
      let date_end = this.image_filter_params.start_date
        .replace("__", " ")
        .replace("_", ":");
      let date_start = moment(
        this.image_filter_params.start_date,
        "YYYY-MM-DD__HH_mm"
      )
        .add(
          this.image_filter_params.group_size.split("_")[0],
          this.image_filter_params.group_size.split("_")[1]
        )
        .format("YYYY-MM-DD HH:mm");

      let species = "";

      let camera = "";

      if (this.image_filter_params.camera_id != "all") {
        var camera_text = "all cameras";
        if (this.image_filter_params.camera_id.length == 1) {
          camera_text =
            this.camera_object[this.image_filter_params.camera_id[0]];
        } else if (this.image_filter_params.camera_id.length > 1) {
          camera_text = `${this.image_filter_params.camera_id.length} cameras`;
        }
        camera = ` coming from ${camera_text}`;
      }

      return `${model}${species} images from ${date_end} to ${date_start}${camera}`;
    },
    how_many_items() {
      if (this.buckets.includes("min")) {
        return [15, 30, 60];
      } else if (this.buckets.includes("day")) {
        return [7, 14, 28];
      } else {
        return [12, 24, 48];
      }
    },

    starting_from_display() {
      return (
        this.weekday(this.date_range) +
        " " +
        this.date_range +
        " " +
        this.time_range
      );
    },
    camera_name_object() {
      var cam_nicknames = [];
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames.push({
            value: this.$store.getters.getCameraObjects[i].id,
            text: this.$store.getters.getCameraObjects[i].name,
          });
        }
      }
      return cam_nicknames;
    },
    camera_object() {
      var cam_nicknames = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames[this.$store.getters.getCameraObjects[i].id] =
            this.$store.getters.getCameraObjects[i].name;
        }
      }
      return cam_nicknames;
    },
    timezeone_offset() {
      if (
        this.$store.getters.getSites &&
        this.$store.getters.getSites[0] &&
        this.$store.getters.getSites[0].timezone
      ) {
        return this.$store.getters.getSites[0].timezone;
      }
      return "UTC";
    },
    site() {
      if (this.$store.getters.getSites) {
        return this.$store.getters.getSites[0];
      }
      return false;
    },

    date_time_range() {
      if (!this.date_range.includes("__") && this.time_range) {
        return this.date_range + " " + this.time_range;
      }
      return false;
    },

    customer_id() {
      return this.$store.getters.getCustomerID;
    },
  },

  created() {
    eventBus.$on("change_tab", (tab) => {
      if (tab == "detections") {
        this.analytics_tabs = 0;
      } else if (tab == "animals") {
        this.analytics_tabs = 1;
      } else if (tab == "bodyparts") {
        this.analytics_tabs = 2;
      } else if (tab == "calving") {
        this.analytics_tabs = 1;
      } else if (tab == "estrus") {
        this.analytics_tabs = 2;
      } else if (tab == "predator") {
        this.analytics_tabs = 1;
      }
    });

    if (this.tab == "detections") {
      this.analytics_tabs = 0;
    } else if (this.tab == "animals") {
      this.analytics_tabs = 1;
    } else if (this.tab == "bodyparts") {
      this.analytics_tabs = 2;
    } else if (this.tab == "calving") {
      this.analytics_tabs = 1;
    } else if (this.tab == "estrus") {
      this.analytics_tabs = 2;
    } else if (this.tab == "predator") {
      this.analytics_tabs = 1;
    }
    eventBus.$on("update_analytics_images_filter", (params) => {
      let buckets = this.buckets.split(" ");
      let sub_unit =
        buckets[1] == "day" ? "days" : buckets[1] == "hr" ? "hours" : "minutes";
      if (!params.reset) {
        let temp_params = {};
        if (!params.camera_id) {
          temp_params.camera_id = this.selected_cameras;
        } else {
          temp_params.camera_id = params.camera_id;
        }
        temp_params.group_size = `${buckets[0]}_${sub_unit}`;
        temp_params.start_date = moment
          .tz(params.start_time, "MM-DD HH:mm", this.timezeone_offset)
          .utc()
          .subtract(buckets[0] * params.time_displace, sub_unit)
          .format("YYYY-MM-DD__HH_mm");

        if (params.class) {
          if (this.analytics_tabs == 0) {
            temp_params.model_filter = {
              classification: { [params.class.replace(" ", "_")]: true },
            };
          } else if (this.analytics_tabs == 1 || this.analytics_tabs == 5) {
            temp_params.model_filter = {
              PGIE: { [params.class.replace(" ", "_")]: true },
            };
          } else if (this.analytics_tabs == 3) {
            temp_params.model_filter = {
              Contraction2: { [params.class.replace(" ", "_")]: true },
            };
          } else if (this.analytics_tabs == 2) {
            temp_params.model_filter = {
              Bodyparts: { [params.class.replace(" ", "_")]: true },
            };
          } else if (this.analytics_tabs == 4) {
            temp_params.model_filter = {
              Estrus: { [params.class.replace(" ", "_")]: true },
            };
          }
        } else {
          temp_params.model_filter = {};
        }
        this.image_filter_params = temp_params;
      } else {
        let start_time = moment
          .tz(this.date_time_range, "YYYY-MM-DD HH:mm", this.timezeone_offset)
          .utc()
          .subtract(buckets[0] * this.bucket_size, sub_unit)
          .format("YYYY-MM-DD__HH_mm");
        this.image_filter_params = {
          group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
          start_date: start_time,
          camera_id: params.camera_id,
          model_filter: params.class,
        };
      }
    });
    if (this.$store.getters.getSites && this.$store.getters.getSites[0]) {
      this.$store.dispatch("DDB_VIDEOS_AT_DATE").then(
        (response) => {
          this.date_range = response[0];
          this.time_range = response[1]
            ? moment.tz(response[1], this.timezeone_offset).format("HH:mm")
            : "12:00";
        },
        (error) => {
          console.error(
            "Got nothing from server. Prompt user to check internet connection and try again",
            error
          );
        }
      );
    }
  },
  watch: {
    per_row() {
      this.updatePerRow();
    },
    analytics_tabs() {
      this.get_analytics();
      let buckets = this.buckets.split(" ");
      let sub_unit =
        buckets[1] == "day" ? "days" : buckets[1] == "hr" ? "hours" : "minutes";
      let start_time = moment
        .tz(this.date_time_range, "YYYY-MM-DD HH:mm", this.timezeone_offset)
        .utc()
        .subtract(buckets[0] * this.bucket_size, sub_unit)
        .format("YYYY-MM-DD__HH_mm");

      if (
        this.analytics_tabs === 0 ||
        this.analytics_tabs === 2 ||
        this.analytics_tabs === 4
      ) {
        this.image_filter_params = {
          start_date: start_time,
          group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
          camera_id: this.selected_cameras,
          model_filter: { pgie_class_label: "animal" },
        };
      } else if (this.analytics_tabs === 1) {
        this.image_filter_params = {
          start_date: start_time,
          group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
          camera_id: this.selected_cameras,
          model_filter: { tag_exists: true },
        };
      } else if (this.analytics_tabs === 3) {
        this.image_filter_params = {
          start_date: start_time,
          group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
          camera_id: this.selected_cameras,
          model_filter: { Contraction: true },
        };
      } else if (this.analytics_tabs === 5) {
        this.image_filter_params = {
          start_date: start_time,
          group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
          camera_id: this.selected_cameras,
          model_filter: { PGIE: { cat: true, canine: true } },
        };
      }
    },

    date_time_range() {
      this.get_analytics();
      let subtract_count = this.buckets.split(" ")[0] * this.bucket_size;
      let time_unit;
      if (this.buckets.split(" ")[1] == "min") {
        time_unit = "minute";
      } else if (this.buckets.split(" ")[1] == "hr") {
        time_unit = "hour";
      } else if (this.buckets.split(" ")[1] == "day") {
        time_unit = "day";
      }
      let start_time = moment
        .tz(this.date_time_range, "YYYY-MM-DD HH:mm", this.timezeone_offset)
        .utc()
        .subtract(subtract_count, time_unit + "s")
        .format("YYYY-MM-DD__HH_mm");

      this.image_filter_params.start_date = start_time;
    },
    buckets() {
      if (
        this.buckets.includes("min") &&
        ![15, 30, 60].includes(this.bucket_size)
      ) {
        this.bucket_size = 30;
      } else if (
        this.buckets.includes("hr") &&
        ![12, 24, 48].includes(this.bucket_size)
      ) {
        this.bucket_size = 24;
      } else if (
        this.buckets.includes("day") &&
        ![7, 14, 28].includes(this.bucket_size)
      ) {
        this.bucket_size = 14;
      } else {
        this.get_analytics();
      }

      let buckets = this.buckets.split(" ");
      let sub_unit =
        buckets[1] == "day" ? "days" : buckets[1] == "hr" ? "hours" : "minutes";
      let start_time = moment
        .tz(this.date_time_range, "YYYY-MM-DD HH:mm", this.timezeone_offset)
        .utc()
        .subtract(buckets[0] * this.bucket_size, sub_unit)
        .format("YYYY-MM-DD__HH_mm");

      this.image_filter_params = {
        group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
        start_date: start_time,
        camera_id: this.image_filter_params.camera_id,
        model_filter: this.image_filter_params.model_filter,
      };
    },
    bucket_size() {
      // if (this.buckets.includes("day") && this.time_range != "00:00") {
      //   this.time_range = "24:00";
      // } else {
      //   this.get_analytics();
      // }
      this.get_analytics();
      let buckets = this.buckets.split(" ");
      let sub_unit =
        buckets[1] == "day" ? "days" : buckets[1] == "hr" ? "hours" : "minutes";
      let start_time = moment
        .tz(this.date_time_range, "YYYY-MM-DD HH:mm", this.timezeone_offset)
        .utc()
        .subtract(buckets[0] * this.bucket_size, sub_unit)
        .format("YYYY-MM-DD__HH_mm");

      this.image_filter_params = {
        group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
        start_date: start_time,
        camera_id: this.image_filter_params.camera_id,
        model_filter: this.image_filter_params.model_filter,
      };
    },
    selected_cameras() {
      this.get_analytics();

      let buckets = this.buckets.split(" ");
      let sub_unit =
        buckets[1] == "day" ? "days" : buckets[1] == "hr" ? "hours" : "minutes";
      let start_time = moment
        .tz(this.date_time_range, "YYYY-MM-DD HH:mm", this.timezeone_offset)
        .utc()
        .subtract(buckets[0] * this.bucket_size, sub_unit)
        .format("YYYY-MM-DD__HH_mm");

      this.image_filter_params = {
        group_size: `${buckets[0] * this.bucket_size}_${sub_unit}`,
        start_date: start_time,
        camera_id: this.selected_cameras,
        model_filter: this.image_filter_params.model_filter,
      };
    },
    site() {
      this.$store.dispatch("DDB_VIDEOS_AT_DATE").then(
        (response) => {
          this.date_range = response[0];
          this.time_range = response[1]
            ? moment.tz(response[1], this.timezeone_offset).format("HH:mm")
            : "12:00";
        },
        (error) => {
          console.error(
            "Got nothing from server. Prompt user to check internet connection and try again",
            error
          );
        }
      );
    },
  },
};
</script>
<style lang="css" scoped>
.chart_loaders {
  transform: translateY(-50%) translateX(-50%);
  top: 50%;

  left: 50%;
}
.charts_card {
  height: calc(100vh - 240px);
  overflow-y: scroll;
  overflow-x: hide;
}
.analytics_charts {
  height: calc((100vh - 248px) / 2);
}

.analytics_charts_ph {
  height: calc((100vh - 240px) / 2);
  text-align: center;
}

.analytics_charts_ph > span {
  top: 45%;
  position: relative;
}
</style>