<template>
  <div
    class="pa-0 grid_container_f"
    :class="'bordered_' + bordered"
    ref="image_container"
  >
    <div
      class="image_overlay_area"
      ref="star_div"
      @click="open_image()"
      @mouseenter="onEnterImg()"
      @mouseleave="onLeaveImg()"
    >
      <span class="datetime_grid" v-if="show_text">
        <span class="darker_bckrnd">{{ get_date(img.media_timestamp) }}</span>
      </span>
      <span class="cam_id_grid" v-if="show_text">
        <span class="darker_bckrnd">{{ cameras[img.camera_id] }}</span>
      </span>
    </div>
    <div class="image_thumbnail_annotation_area" ref="mainCanvas_div">
      <canvas class="image_thumbnail_main_canvas" ref="mainCanvas"></canvas>
    </div>
    <vue-load-image style="width: 100%; height: 100%" @onLoad="startCanvas()">
      <img
        slot="image"
        alt=""
        :src="`${cookie_bucket + get_path(img) + '_event.jpg'}`"
        class="grid_img_f"
        ref="image"
      />
      <img slot="preloader" class="grid_img_f" src="@/assets/loader.png" />
      <div slot="error" class="no-image">
        <div>No<br />Image</div>
      </div>
    </vue-load-image>
  </div>
</template>

<script>
import VueLoadImage from "vue-load-image";
import { eventBus } from "../main";
import moment from "moment-timezone";

export default {
  props: {
    img: Object,
    image_filters: Object,
    show_text: Boolean,
    bordered: Boolean,
    img_index: Number,
    show_bbox: Boolean,
    show_hover: Boolean,
    disabled: Boolean,
  },
  components: {
    "vue-load-image": VueLoadImage,
  },
  data() {
    return {
      star_icon: "mdi-checkbox-blank-outline",
      selected: false,
    };
  },
  watch: {
    show_bbox() {
      this.updateCanvas();
    },
  },
  methods: {
    get_date(date) {
      return moment.utc(date, "YYYY-MM-DD HH:mm:ss").tz(this.timezeone_offset).format(
        "YYYY-MM-DD hh:mm:ss A"
      );
    },
    toggle_select(e) {
      e?.stopPropagation();
      this.selected = !this.selected;
      if (this.selected) {
        this.star_icon = "mdi-checkbox-marked";
      } else {
        this.star_icon = "mdi-checkbox-blank-outline";
      }
      this.$emit("select", this.img.image_path);
    },
    get_path(img) {
      var year = img.media_timestamp.substr(0, 4);
      var month = img.media_timestamp.substr(5, 2);
      var day = img.media_timestamp.substr(8, 2);
      var full_date = img.media_timestamp
        .replaceAll(":", "-")
        .replace("T", "_");
      return `${img.customer_id}/${img.camera_id}/${year}/${month}/${day}/${img.camera_id}_${full_date}`;
    },
    open_image() {
      var params = {
        img: this.img,
        image_index: this.img_index,
      };
      eventBus.$emit("open_dialog_images", params);
    },
    startCanvas() {
      this.$nextTick(() => {
        const resizeObserver = new ResizeObserver(() => {
          this.updateCanvas();
        });

        resizeObserver.observe(this.$refs.image);
      });
    },
    onEnterImg() {
      if (!this.show_hover) {
        return;
      }
      var image_container = this.$refs.image_container;
      var image = this.$refs.image;
      var star_div = this.$refs.star_div;
      var canvas_div = this.$refs.mainCanvas_div;
      var canvas = this.$refs.mainCanvas;

      if (image_container) {
        image_container.style.zIndex = "99";
      }

      if (image) {
        image.style.transform = "scale(1.3)";
        image.style.boxShadow = "0 0 10px black";
        image.style.zIndex = "99";
      }

      if (canvas && canvas_div) {
        canvas_div.style.zIndex = "100";
        canvas.style.zIndex = "100";

        canvas.style.transform = "scale(1.3)";
      }

      if (star_div) {
        star_div.style.zIndex = "101";

        star_div.style.transform = "scale(1.3)";
      }
    },
    onLeaveImg() {
      if (!this.show_hover) {
        return;
      }
      var image_container = this.$refs.image_container;
      var image = this.$refs.image;
      var star_div = this.$refs.star_div;
      var canvas_div = this.$refs.mainCanvas_div;
      var canvas = this.$refs.mainCanvas;

      if (image_container) {
        image_container.style.zIndex = null;
      }

      if (image) {
        image.style.transform = null;
        image.style.boxShadow = null;
        image.style.zIndex = null;
      }

      if (canvas && canvas_div) {
        canvas_div.style.zIndex = "1";
        canvas.style.zIndex = null;

        canvas.style.transform = null;
      }

      if (star_div) {
        star_div.style.zIndex = "1";

        star_div.style.transform = null;
      }
    },
    updateCanvas() {
      var canvas = this.$refs.mainCanvas;
      if (!canvas) {
        return;
      }
      canvas.style.width = this.$refs.image.clientWidth + "px";
      canvas.style.height = this.$refs.image.clientHeight + "px";
      canvas.width = this.$refs.image.clientWidth;
      canvas.height = this.$refs.image.clientHeight;
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (!this.show_bbox) {
        return;
      }
      ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
      if (this.img.pgies && !this.img.bbox) {
        this.draw_bbox(ctx, this.img.pgies, this.get_path(this.img));
      } else if (this.img.bbox) {
        this.draw_bbox_calving(ctx, this.img, this.get_path(this.img));
      } else if (this.img.bbox_x) {
        this.draw_bbox_estrus(ctx, this.img, this.get_path(this.img));
      }
    },
    scale(coord, x) {
      var canvas_width = this.$refs.mainCanvas.width;
      return Math.round(coord * (canvas_width / x));
    },
    //     getMeta(url, callback) {
    //     const img = new Image();
    //     img.src = url;
    //     img.onload = function() { callback(this.width); }
    // },
    async draw_bbox(ctx, arr, path) {
      // console.log("drawn");
      // console.log(arr);
      var x = 2560;

      const getMeta = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
          img.src = url;
        });

      // Use like:
      x = (await getMeta(`${this.cookie_bucket + path + "_event.jpg"}`))
        .naturalWidth;

      for (let i = 0; i < arr.length; i++) {
        // filled rectangle
        // if(
        //   (arr[i].type=="head"&&!this.image_filters.SGIE.head) ||
        //   (arr[i].type=="tail"&&!this.image_filters.SGIE.tail) ||
        //   (arr[i].type=="tag"&&!this.image_filters.SGIE.tag)
        // ){
        //   return;
        // }

        //  `${this.cookie_bucket + path + "_event.jpg"}`,

        var x1 = this.scale(arr[i].bbox.x, x);
        var y1 = this.scale(arr[i].bbox.y, x);
        var w = this.scale(arr[i].bbox.w, x);
        var h = this.scale(arr[i].bbox.h, x);
        ctx.fillStyle =
          this.$store.getters.getBBOXColorCode[
            arr[i].classifications.species_label
          ]?.fillStyle || "rgba(242, 114, 0, 0.4)";
        ctx.fillRect(x1, y1, w, h);
        // border modifications
        ctx.lineWidth = 1;
        ctx.strokeStyle =
          this.$store.getters.getBBOXColorCode[
            arr[i].classifications.species_label
          ]?.strokeStyle || "rgba(242, 114, 0)";
        ctx.strokeRect(x1, y1, w, h);
      }
    },
    async draw_bbox_calving(ctx, img, path) {
      // console.log("drawn");
      // console.log(arr);
      var x = 2560;

      const getMeta = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
          img.src = url;
        });

      // Use like:
      x = (await getMeta(`${this.cookie_bucket + path + "_event.jpg"}`))
        .naturalWidth;

      var x1 = this.scale(img.bbox.x, x);
      var y1 = this.scale(img.bbox.y, x);
      var w = this.scale(img.bbox.w, x);
      var h = this.scale(img.bbox.h, x);
      ctx.fillStyle =
        this.$store.getters.getBBOXColorCode[img.species_label]?.fillStyle ||
        "rgba(242, 114, 0, 0.4)";
      ctx.fillRect(x1, y1, w, h);
      // border modifications
      ctx.lineWidth = 1;
      ctx.strokeStyle =
        this.$store.getters.getBBOXColorCode[img.species_label]?.strokeStyle ||
        "rgba(242, 114, 0)";
      ctx.strokeRect(x1, y1, w, h);
    },
    async draw_bbox_estrus(ctx, img, path) {
      // console.log("drawn");
      // console.log(arr);
      var x = 2560;

      const getMeta = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
          img.src = url;
        });

      // Use like:
      x = (await getMeta(`${this.cookie_bucket + path + "_event.jpg"}`))
        .naturalWidth;

      var x1 = this.scale(img.bbox_x, x);
      var y1 = this.scale(img.bbox_y, x);
      var w = this.scale(img.bbox_w, x);
      var h = this.scale(img.bbox_h, x);
      ctx.fillStyle =
        this.$store.getters.getBBOXColorCode[img.species_label]?.fillStyle ||
        "rgba(242, 114, 0, 0.4)";
      ctx.fillRect(x1, y1, w, h);
      // border modifications
      ctx.lineWidth = 1;
      ctx.strokeStyle =
        this.$store.getters.getBBOXColorCode[img.species_label]?.strokeStyle ||
        "rgba(242, 114, 0)";
      ctx.strokeRect(x1, y1, w, h);
    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          this.bordered ? "lf_blue_active" : "lf_blue",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          this.bordered ? "lf_yellow_active" : "lf_yellow",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else {
        return [false, false];
      }
    },
  },
  mounted() {
    // this.updateCanvas();
  },
  created() {
    eventBus.$on("select_all_images", this.toggle_select);
  },
  computed: {
    timezeone_offset() {
      if (this.$store.getters.getSites && this.$store.getters.getSites[0]) {
        return this.$store.getters.getSites[0].timezone;
      }
      return "UTC";
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    cameras() {
      let cams = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cams[this.$store.getters.getCameraObjects[i].id] =
            this.$store.getters.getCameraObjects[i].name;
        }
      }
      return cams;
    },
  },
};
</script>

<style scoped>
.bordered_true {
  border: 3px solid #f57c00;
}
.grid_container_f {
  position: relative;
  text-align: center;
  aspect-ratio: 1.78;

  background: black;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.1s;
}
.datetime_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 1px;
  padding-right: 1px;
}
.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.user_id_grid {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ago_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}

.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}

.image_thumbnail_annotation_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.image_thumbnail_main_canvas {
  pointer-events: none;
  transition-duration: 0.1s;
}

.image_overlay_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-duration: 0.1s;
  z-index: 2;
}

.image_star {
  float: right;
}
</style>