<template>
  <div>
    <v-container fluid class="pa-0">
      <v-card :loading="loading" style="height: calc(100vh - 240px);
  overflow-y: scroll;
  overflow-x: hide;">
        <!-- image list -->
        <v-row class="ml-0 mr-0 mt-0 mb-0">
          <div id="grid-container-lf">
            <image-thumbnail
              v-for="(p, index) in image_paths"
              :key="p.image_path + '_' + index"
              :img="p"
              :img_index="index"
              :show_text="show_text"
              :show_hover="show_hover"
              :bordered="selected_image_index === index"
              :image_filters="image_filters"
              :show_bbox="show_bbox"
            ></image-thumbnail>
          </div>
          <div class="skeleton_container">
            <v-skeleton-loader
              v-if="more_images"
              v-intersect.quiet="nextPage"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </div>
        </v-row>
      </v-card>
    </v-container>

    <v-dialog v-model="open_image"  :max-width="showSideContainers ? `1440px` : '1000px'"  style="margin: 0"
      ><v-card style="min-width: 1000px" v-if="image_paths && image_paths[selected_image_index]"
        ><v-card-title>
          <span
            >{{ get_time(image_paths[selected_image_index])  }}
          </span>
          <v-spacer></v-spacer>
          <span
            @click="nav_to_table(image_paths[selected_image_index].camera_id)"
            class="mr-4"
            style="
              display: block;
              color: grey;
              font-size: 18px;
              margin-bottom: -2px;
              cursor: pointer;
            "
            ><v-icon>mdi-cctv</v-icon>
            {{ cameras_object[image_paths[selected_image_index].camera_id] }}
          </span>

          <span style="float: right">
            <v-icon
              :disabled="selected_image_index == 0"
              @click="selected_image_index -= 1"
              class="mr-1"
              >mdi-arrow-left</v-icon
            ><v-icon
              :disabled="selected_image_index == image_paths.length - 1"
              @click="selected_image_index += 1"
              class="ml-1"
              >mdi-arrow-right</v-icon
            ></span
          >
          <v-icon
            class="ml-2 mt-1"
            large
            v-if="fullscreen_img"
            @click="fullscreen_img = false"
            >mdi-fullscreen-exit</v-icon
          ></v-card-title
        ><v-card-text>
          <div style="position: relative">
            <image-inf
              :img_src="[
                [
                  {
                    img: image_paths[selected_image_index],
                    image_index: selected_image_index,
                  },
                ],
                cookie_bucket +
                  get_path(image_paths[selected_image_index]) +
                  '_event.jpg',
              ]"
            />
            <!-- <span
              id="fullscreen_notification_img"
              @click="fullscreen_img = true"
              ><v-icon style="height: 100%; width: 100%" large
                >mdi-fullscreen</v-icon
              ></span
            >
            <span id="download_notification_img">
              <a
                :href="
                  cookie_bucket +
                  image_paths[selected_image_index].image_path +
                  '_event.jpg'
                "
                style="height: 100%; width: 100%"
                download
              >
                <v-icon style="height: 100%; width: 100%">mdi-download</v-icon>
              </a></span
            > -->
          </div>
        </v-card-text>
      </v-card></v-dialog
    >
    <!-- <image-card></image-card> -->
  </div>
</template>
  
<script>
// import VueLoadImage from "vue-load-image";
import moment from "moment-timezone";
//import ImageCard from "../components/ImageCard.vue";
import ImageThumbnail from "../components/ImageThumbnail.vue";
import { eventBus } from "../main";
import JSZip from "jszip";
import ImageInf from "@/components/ImageInf.vue";

export default {
  components: {
    ImageInf,
    // "vue-load-image": VueLoadImage,

    ImageThumbnail,
  },
  props: ["filter", "show_text", "show_bbox", "show_hover"],
  data() {
    return {
       showSideContainers: window.innerWidth >= 1470,
      selected_image_index: false,
      fullscreen_img: false,
      loading: false,
      per_page: 64,
      page_limits: [48, 64, 80, 96, 112],
      per_row: 6,
      row_limits: [2, 4, 6, 8, 12],

      selected_cameras: [],
      bordered: false,
      date_range: [],
      time_range: {},
      image_filters: {
        PGIE: {},
        Contraction: false,
        Mounting: false,
        Activity: {},
        Age: {},
      },
      open_image: false,
      PGIE_count: 0,
      SGIE_count: 0,
      Contraction_count: 0,
      Activity_count: 0,
      Age_count: 0,
      Suckling_count: 0,
      todays_date: moment().format("YYYY-MM-DD"),
      PGIE_type_list: [
        "cat",
        "cow",
        "dog",
        "horse",
        "pig",
        "sheep",
        "coyote",
        "bear",
        "bison",
      ],
      SGIE_type_list: ["head", "tail"],
      image_type_list: ["contraction", "predator", "drop tag"],
      model_version_list: [
        "pgie_yolox_ensemble",
        "sgie_dog_activity",
        "sgie_cow_suckling",
        "sgie_keypoint_0_ensemble",
        "sgie_cow_body_part_ensemble",
        "sgie_cow_age",
        "sgie_contraction",
        "sgie_dog_body_part_ensemble",
        "sgie_cow_ocr",
        "sgie_cow_activity",
      ],
      more_images: false,

      // model versions
      model_versions: {},

      selected_images: [],
      check_all_icon: "mdi-checkbox-multiple-blank-outline",
      sort_direction: "DESC",
    };
  },
  methods: {
    checkScreenWidth() {
      this.showSideContainers = window.innerWidth >= 1470;
    },
    get_time(img) {
      var image_time = moment(img.media_timestamp);
      return image_time.format("YYYY-MM-DD h:mm:ss A");
    },
    get_path(img) {
      var year = img.media_timestamp.substr(0, 4);
      var month = img.media_timestamp.substr(5, 2);
      var day = img.media_timestamp.substr(8, 2);
      var full_date = img.media_timestamp
        .replaceAll(":", "-")
        .replace("T", "_");
      return `${img.customer_id}/${img.camera_id}/${year}/${month}/${day}/${img.camera_id}_${full_date}`;
    },
    nav_to_table(device_id) {
      this.$router.push({
        name: "videos",
        params: {
          cameraname: device_id,
        },
      });
    },
    get_date(date) {
      return moment.utc(date, "YYYY-MM-DD HH:mm:ss").tz(this.timezeone_offset).format("YYYY-MM-DD hh:mm A");
    },
    weekday(date) {
      var dayofweek;
      if (moment(date, "YYYY-MM-DD").weekday() == 0) {
        dayofweek = "Sun";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 1) {
        dayofweek = "Mon";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 2) {
        dayofweek = "Tue";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 3) {
        dayofweek = "Wed";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 4) {
        dayofweek = "Thur";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 5) {
        dayofweek = "Fri";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 6) {
        dayofweek = "Sat";
      }
      return dayofweek;
    },
    // change_date(next) {
    //   if (next) {
    //     this.date_range = moment(this.date_range, "YYYY-MM-DD")
    //       .add(1, "days")
    //       .format("YYYY-MM-DD");
    //   } else {
    //     this.date_range = moment(this.date_range, "YYYY-MM-DD")
    //       .subtract(1, "days")
    //       .format("YYYY-MM-DD");
    //   }
    // },
    update_sort_direction() {
      if (this.sort_direction == "DESC") {
        this.sort_direction = "ASC";
      } else {
        this.sort_direction = "DESC";
      }
    },
    selectAllIcon() {
      if (this.check_all_icon != "mdi-checkbox-multiple-blank-outline") {
        this.check_all_icon = "mdi-checkbox-multiple-blank-outline";
      } else {
        this.check_all_icon = "mdi-checkbox-multiple-marked";
      }
      eventBus.$emit("select_all_images");
    },
    async downloadAllJSONs() {
      this.loading = true;

      var zip = JSZip();
      for (let i = 0; i < this.selected_images.length; i++) {
        zip = await this.fetchJSON(zip, this.selected_images[i]);
      }

      zip.generateAsync({ type: "blob" }).then(
        (blob) => {
          this.downloadBlob(blob, "JSONExport.zip");
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    },
    fetchJSON(zip, image_path) {
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + ".json.zip";
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.loadAsync(data);
          })
          .then((zip) => {
            resolve(zip);
          });
      });
    },
    downloadBlob(blob, filename) {
      var blobUrl = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    select_image(i) {
      console.log(i);
    },
    select_filter(model, classification) {
      for (let key in this.image_filters[model]) {
        if (key != classification) {
          this.image_filters[model][key] = false;
        }
      }

      var f_count = 0;
      for (let key in this.image_filters[model]) {
        if (this.image_filters[model][key]) {
          f_count++;
        }
      }

      this[`${model}_count`] = f_count;
    },

    initializeDataRange() {
      var date = moment().utc();
      var now = date.format("YYYY-MM-DD");
      var temp_date_range = [];
      temp_date_range[1] = now;
      temp_date_range[0] = moment(now, "YYYY-MM-DD")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.date_range = temp_date_range;
    },

    fetchImagePaths(load_more) {
      if (this.query_params) {
        var temp_query_params = { ...this.query_params };
        temp_query_params.load_more = load_more;
        this.loading = true;
        this.$store
          .dispatch("DDB_GET_IMAGES", temp_query_params)
          .then((more_images) => {
            this.more_images = more_images;
            this.loading = false;
          });
      }
    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          this.bordered ? "lf_blue_active" : "lf_blue",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          this.bordered ? "lf_yellow_active" : "lf_yellow",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else {
        return [false, false];
      }
    },
    nextPage(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchImagePaths(true);
      }
    },
    set_borders(upd_bor) {
      if (upd_bor) {
        this.bordered = !this.bordered;
      }
      if (!this.bordered) {
        document.querySelectorAll(".lf_green_active").forEach(function (cl) {
          cl.classList.replace("lf_green_active", "lf_green");
        });
        document.querySelectorAll(".lf_blue_active").forEach(function (cl) {
          cl.classList.replace("lf_blue_active", "lf_blue");
        });
        document.querySelectorAll(".lf_yellow_active").forEach(function (cl) {
          cl.classList.replace("lf_yellow_active", "lf_yellow");
        });
        document.querySelectorAll(".lf_orange_active").forEach(function (cl) {
          cl.classList.replace("lf_orange_active", "lf_orange");
        });
      } else {
        document.querySelectorAll(".lf_green").forEach(function (cl) {
          cl.classList.replace("lf_green", "lf_green_active");
        });
        document.querySelectorAll(".lf_blue").forEach(function (cl) {
          cl.classList.replace("lf_blue", "lf_blue_active");
        });
        document.querySelectorAll(".lf_yellow").forEach(function (cl) {
          cl.classList.replace("lf_yellow", "lf_yellow_active");
        });
        document.querySelectorAll(".lf_orange").forEach(function (cl) {
          cl.classList.replace("lf_orange", "lf_orange_active");
        });
      }
    },
    updatePerRow() {
      var container = document.getElementById("grid-container-lf");
      container.style["grid-template-columns"] = `repeat(${this.per_row}, 1fr)`;
    },
  },
  watch: {
    open_image() {
      if (!this.open_image) {
        this.selected_image_index = false;
      }
    },
    customer_id() {
      this.$store.dispatch("DDB_VIDEOS_AT_DATE").then(
        (response) => {
          var temp_date_range = [];
          temp_date_range[0] = response[0];
          // temp_date_range[0] = moment(response[0], "YYYY-MM-DD")
          //   .subtract(1, "days")
          //   .format("YYYY-MM-DD");
          this.time_range = { start: "00:00", end: "24:00" };
          this.date_range = temp_date_range;
        },
        (error) => {
          console.error(
            "Got nothing from server. Prompt user to check internet connection and try again",
            error
          );
        }
      );
    },

    query_params: {
      handler() {
        this.more_images = false
        this.fetchImagePaths();
      },
      deep: true,
    },
  },
  computed: {
    timezeone_offset() {
      if (this.$store.getters.getSites && this.$store.getters.getSites[0]) {
        return this.$store.getters.getSites[0].timezone;
      }
      return "UTC";
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    query_params() {
      if (this.customer_id || this.filter.start_date != 'all') {
        var date_range = [];

        date_range.push(
          moment(this.filter.start_date, "YYYY-MM-DD__HH_mm").format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        date_range.push(
          moment(this.filter.start_date, "YYYY-MM-DD__HH_mm").add(
            this.filter.group_size.split("_")[0],
            this.filter.group_size.split("_")[1]
          ).format("YYYY-MM-DDTHH:mm:ss")
        );

        // var image_filters = {
        //   Activity: {},
        //   Age: {},
        //   PGIE: {},
        //   Contraction: false,
        //   Mounting: false,
        // };

        var params = {
          limit: 64,
          date_range: date_range,
          filters: this.filter.model_filter,
          model_versions: {},
          user_id: this.$store.getters.getCustomerID,
          camera_id: this.filter.camera_id,
          sort: "DESC"        
        };
        return params;
      }
      return undefined;
    },

    cameras_object() {
      let cams = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cams[this.$store.getters.getCameraObjects[i].id] =
            this.$store.getters.getCameraObjects[i].name;
        }
      }
      return cams;
    },
    camera_name_object() {
      var cam_nicknames = [];
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames.push({
            value: this.$store.getters.getCameraObjects[i].id,
            text: this.$store.getters.getCameraObjects[i].name,
          });
        }
      }
      return cam_nicknames;
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    image_paths() {
      if (this.$store.getters.getImages) {
        return this.$store.getters.getImages;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("keydown", (e) => {
      if (this.open_image) {
        if (
          e.key == "ArrowRight" &&
          this.selected_image_index < this.image_paths.length - 1
        ) {
          this.selected_image_index += 1;
        } else if (e.key == "ArrowLeft" && this.selected_image_index > 0) {
          this.selected_image_index -= 1;
        }
      }
    });
  },

  destroyed() {
    this.$store.dispatch("DDB_GET_IMAGES", "empty");
  },
  created() {
    eventBus.$on(
      "open_dialog_images",
      (notification) => {
        this.selected_image_index = notification.image_index
        this.open_image = true
      }
      
    );

    eventBus.$on(
      "refresh_analytics_images",
      () => (
        this.fetchImagePaths()
      )
    );
    eventBus.$on("previousImage", (index) => {
      index = index - 1;
      if (index < 0) {
        index = this.image_paths.length - 1;
      }
      var img = this.image_paths[index];
      var params = {
        img: img,
        image_index: index,
      };
      eventBus.$emit("open_dialog_images", params);
    });

    eventBus.$on("nextImage", (index) => {
      index = index + 1;
      if (index >= this.image_paths.length) {
        index = 0;
      }
      var img = this.image_paths[index];
      var params = {
        img: img,
        image_index: index,
      };
      eventBus.$emit("open_dialog_images", params);
    });
  },
};
</script>
<style lang="css" scoped>
.filter_button {
  font-size: 15px;
  letter-spacing: inherit !important;
  text-transform: none;
}
#lf_btns {
  /* width: 75px !important; */
  display: flex;
  float: right;
  margin-right: 6px;
  margin-top: 5px;
}
.skeleton_container {
  width: 100%;
}
.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}
#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
}
.grid_container_f {
  position: relative;
  text-align: center;
}
/* .grid_container_f:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
  z-index: 2 !important;
} */
.grid_container_f:hover > .datetime_grid {
  z-index: 3 !important;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.darker_bckrnd {
  background-color: rgba(0, 0, 0, 0.377);
  padding-left: 1px;
  padding-right: 1px;
}
.datetime_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ago_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}
.user_id_grid {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image_filter_menu {
  z-index: 101 !important;
}
</style>
  
